export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    
    function buttonShow() {
      $('.button_wrapper_two').addClass('opacityadd');
    }
    
    // document ready
    $(function() {
      setTimeout(buttonShow, 1000);
    });
    
    if ($('#customer_login')[0]){
      document.body.setAttribute('id', 'login_page');
    } else {
      // Do something if class does not exist
    }

    if ($('.passwordreset')[0]){
      document.body.setAttribute('id', 'login_page');
    } else {
      // Do something if class does not exist
    }
    
  },
};
