export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    
    function homeAnimation() {
      $('.text_one').addClass('opacityadd');
    }

    function homeAnimationTwo() {
      $('.text_two').addClass('opacityadd');
    }

    function contentShow() {
      $('.contentblock').addClass('opacityadd');
    }

    function buttonShow() {
      $('.button_wrapper').addClass('opacityadd');
    }

    function moveUpText() {
      $('.text_one').addClass('moveup');
    }
    
    // document ready
    $(function() {
      setTimeout(homeAnimation, 1000);
      setTimeout(homeAnimationTwo, 2000);
      setTimeout(moveUpText, 3000);
      setTimeout(contentShow, 4000);
      setTimeout(buttonShow, 4500);
    });
    
  },
};
